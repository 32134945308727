import { createStore, combineReducers, compose, applyMiddleware } from "redux";
import { DEFAULT_STATE } from "../constants/general";
import user from "./reducers/user";
import error from "./reducers/error";
import loading from "./reducers/loading";
import city from "./reducers/city";
import cityCurrent from "./reducers/city-current";
import connectedUsers from "./reducers/connected-users";
import country from "./reducers/country";
import countryCurrent from "./reducers/country-current";
import grade from "./reducers/grade";
import gradeCurrent from "./reducers/grade-current";
import ore from "./reducers/ore";
import oreCurrent from "./reducers/ore-current";
import results from "./reducers/results";
import roleCurrent from "./reducers/role-current";
import roles from "./reducers/roles";
import schoolCurrent from "./reducers/school-current";
import schools from "./reducers/schools";
import stateProvinces from "./reducers/states-province";
import stateProvinceCurrent from "./reducers/states-province-current";
import studentsActivity from "./reducers/students-activity";
import subjectCurrent from "./reducers/subject-current";
import subjects from "./reducers/subjects";
import { reducer as toastrReducer } from "react-redux-toastr";
import thunk from "redux-thunk";

/* immutable. Just one*/
const appReducer = combineReducers({
    loading,
    user,
    city,
    cityCurrent,
    connectedUsers,
    country,
    countryCurrent,
    grade,
    gradeCurrent,
    ore,
    oreCurrent,
    results,
    roleCurrent,
    roles,
    schoolCurrent,
    schools,
    stateProvinces,
    stateProvinceCurrent,
    studentsActivity,
    subjectCurrent,
    subjects,
    error,
    toastr: toastrReducer,
});

const rootReducer = (state, action) => {
    if (action.type === DEFAULT_STATE) {
        state = undefined;
    }

    return appReducer(state, action);
};

const globalState = localStorage.getItem("GLOBAL_STATE");
const initialState = globalState ? JSON.parse(globalState) : undefined;


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(thunk))
);

export const saveState = () => {
    const state = store.getState();
    const stateClone = { ...state, error: null, toastr: undefined };
    localStorage.setItem("GLOBAL_STATE", JSON.stringify(stateClone));
};

export default store;
