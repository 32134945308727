import React, { useEffect, useState } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  HashRouter,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { useSelector } from "react-redux";
import { saveState } from "./redux/store";
import ReduxToastr, { toastr } from "react-redux-toastr";
import { createBrowserHistory } from "history";
import Login from "./components/login/index";
import { environment, BASE_URL_SITE_TEACHER } from "./config/environment";
import PrivateRouter from "./components/private/index";
import Analysis from "./components/analysis";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

export const history = createBrowserHistory();

const App = () => {
  const { error } = useSelector((state) => ({
    error: state.error,
  }));

  useEffect(() => {
    window.addEventListener("unload", saveState);
    window.addEventListener("message", (e) => {
      if (e.origin !== BASE_URL_SITE_TEACHER) {
        return;
      }
      console.log("message", e.data);
      const user = e.data;
      localStorage.setItem("auth", JSON.stringify(user));
    });
  }, []);

  useEffect(() => {
    if (error) {
      toastr.error(error);
    }
  }, [error]);

  return (
    <div>
      <HashRouter history={history}>
        <Switch>
          <PrivateRouter path="/analysis" component={Analysis} />
          <Route exact path="/login" component={Login} />
          <Redirect from="/" to="login" />
        </Switch>
      </HashRouter>
      <ReduxToastr />
    </div>
  );
};

export default App;
