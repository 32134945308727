import { LOADING } from '../../constants/general';

/** Dispatch: fetch data action and received the data. Payload the data or the error message**/
const loading = (loading) => {
    return {
        type: LOADING,
        payload: loading
    };
};

export default loading;