import React, { useState, useEffect, useRef } from "react";
import "./style.css";
import GenialSkills from "../../../../assets/images/logo-GenialSkills.svg";
import MenuIcon from "../../../../assets/images/user-menu-icon.svg";
import UserIcon from "../../../../assets/images/sample-avatar.png";
import DropdownUser from "../dropdown-user";
import { getUser } from "../../../../utils/utils";
import { useTranslation } from "react-i18next";

export function useComponentVisible(initialIsVisible) {
    const [isComponentVisible, setIsComponentVisible] = useState(
        initialIsVisible
    );
    const ref = useRef(null);

    const handleHideDropdown = (event) => {
        if (event.key === "Escape") {
            setIsComponentVisible(false);
        }
    };

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setIsComponentVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", handleHideDropdown, true);
        document.addEventListener("click", handleClickOutside, true);
        return () => {
            document.removeEventListener("keydown", handleHideDropdown, true);
            document.removeEventListener("click", handleClickOutside, true);
        };
    });

    return { ref, isComponentVisible, setIsComponentVisible };
}

const Header = () => {
    const {
        ref,
        isComponentVisible,
        setIsComponentVisible,
    } = useComponentVisible(false);

    const { t, i18n } = useTranslation(["home"]);

    return (
        <>
            <div className="wrapper-header">
                <header className="main-header">
                    <div className="main-header_GenialSkills-logo">
                        <img src={GenialSkills} alt="" />
                    </div>

                    <div className="main-header_top-bar-menu">

                        <div className="user-comp">
                            <ul className="user-comp_profile-wrapper">
                                <li ref={ref}>
                                    <div
                                        className="user-comp_profile"
                                        onClick={() => setIsComponentVisible(!isComponentVisible)}
                                    >
                    <span className="user-menu-icon">
                      <img src={MenuIcon} alt="" />
                    </span>
                                        <div className="double-border--principal">
                                            <img src={UserIcon} alt="" />
                                        </div>
                                        <span className="user-name">{getUser().Name}</span>
                                    </div>
                                    {isComponentVisible && (
                                        <DropdownUser
                                            setIsComponentVisible={setIsComponentVisible}
                                            user={getUser()}
                                        />
                                    )}
                                </li>
                            </ul>
                        </div>
                    </div>
                </header>
            </div>
        </>
    );
};

export default Header;
