import React from "react";
import { Switch, Redirect } from "react-router-dom";
import PrivateRouter from "../private";
import Header from "./shared-components/navigation-bar";
import Dashboard from "./dashboard";
import Footer from "./shared-components/footer";
import ReportUser from "./report-user";
import ReportConnections from "./report-connections";
import ReportAcademics from "./report-academics";
import ReportStudentsActivityTeacher from "./report-students-activity-teacher";

const Analysis = () => {
    return (
        <>
            <Header />
            <Switch>
                <PrivateRouter exact path="/analysis/dashboard" component={Dashboard} />
                <PrivateRouter exact path="/analysis/users" component={ReportUser}/>
                <PrivateRouter exact path="/analysis/connections" component={ReportConnections}/>
                <PrivateRouter exact path="/analysis/academics" component={ReportAcademics}/>
                <PrivateRouter exact path="/analysis/student-activities-teacher" component={ReportStudentsActivityTeacher}/>
                <Redirect from="/analysis" to="/analysis/dashboard"  />
            </Switch>
            <Footer/>
        </>
    );
};

export default Analysis;
