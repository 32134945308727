import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import store from "./redux/store";
import "./config/i18n";
import { Loader } from "./components/common/styled-components/loader";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";

const msalInstance = new PublicClientApplication(msalConfig);

const accounts = msalInstance.getAllAccounts();
if (accounts && accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<Loader />}>
      <MsalProvider instance={msalInstance}>
        <App />
      </MsalProvider>
    </Suspense>
  </Provider>,
  document.getElementById("root")
);
