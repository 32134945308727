export const environment = {
    baseURL: process.env.REACT_APP_BASE_URL_API
};

export const BASE_URL_SITE_TEACHER = process.env.REACT_APP_BASE_URL_SITE_TEACHER;
export const ENVIROMENT = process.env.REACT_APP_ENV;
export const LOGIN_PAGE = "./#/login";

export const USERS_API_URL = process.env.REACT_APP_USERS_API_URL;

export const ROLE_EMPLOYEE_NAME = "EMPLOYEE";
export const ROLE_TEACHER_NAME = "TEACHER";
export const ROLE_ADMIN_NAME = "ADMIN";
export const ROLE_PRINCIPAL_NAME = "DIRECTOR";
export const ROLE_ADMIN_ORE_NAME = "ADMIN-ORE"
